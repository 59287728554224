<div class="wrapper page container">

  <div class="row page-title">
    <div class="col-md-12 col-xs-12">
      <h3 class="float-left m-bottom-0">{{'external-api.title' | translate}}</h3>
    </div>
  </div>
  <div class="row border-bottom">
    <ul class="nav">
      <li class="nav-item">
        <a class="nav-link tab" routerLinkActive="active" [routerLink]="'./application/'"
          >{{'nav.external-application' | translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link tab" routerLinkActive="active" [routerLink]="'./merchant/'"
          >{{'nav.external-merchant' | translate}}</a>
      </li>
    </ul>
  </div>
  <div>
    <router-outlet></router-outlet>
  </div>

</div>
