/* src/app/routes/external-apis/external-api/external-api.component.scss */
.tab {
  border-bottom-width: 1px;
  border-bottom-color: #e6e6e6;
  font-weight: 600;
  font-size: 15px;
  padding-left: 30px;
  padding-right: 30px;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}
.tab.active {
  color: #4089fb !important;
  border-bottom-width: 1px;
  border-bottom-color: #4089fb !important;
  border-bottom-style: solid;
}
/*# sourceMappingURL=external-api.component.css.map */
